<template>
<header class="text-center bg-blue-800 text-white p-4 mb-10">
  <div class="text-3xl md:text-5xl font-bold mb-3"> <i class="fa fa-viruses"></i>Covid Tracker </div>

  <p>API by <a class="text-blue-300" href="https://covid19api.com" target="_blank">covid19api.com</a></p>
</header>
</template>

<script>
export default {
  name: 'Header'
}
</script>